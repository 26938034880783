/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { ThemeProvider, CSSReset, Flex } from "@chakra-ui/core"

import Header from "./header"
import Footer from "./footer"

const Layout = ({ children, title, headerContainer,img, landingPage = false }) => {
 
  return (
    <ThemeProvider>
      <CSSReset />
      <Flex minHeight="100vh" flexDirection="column">
        <Header
          showMenu={!landingPage}
          title={title}
          headerContainer={headerContainer}
          img={img}
        />
        <Flex flexDirection="column" flexGrow="1"  style={{ justifyContent: "center", alignItems: "center" }}>
          {children}
        </Flex>

        <Footer />
      </Flex>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
