import React from "react"

import { Flex, Box, Text, Link, Icon, Stack } from "@chakra-ui/core"
import imgLogo from "../images/logo.png"

export default () => (
  <Flex
    mt={0}
    p={2}
    direction="row"
    justify="space-around"
    alignItems="center"
    style={{
      backgroundColor: "#f7f7f3",
      zIndex: "100",
      color: "black",
      borderTop: "solid 1px #e1e1e1",
    }}
  >
    {/* <Flex>
      <Box
        as={"a"}
        p={"10px"}
        rel="noopener noreferrer"
        title="Les Compas zOOm"
        href="http://www.lecompas.fr/ressource/nos-publications/"
        target="_blank"
      >
        <Text fontSize="sm">Retrouvez nos publications Compas zOOm</Text>
        <img
          src="http://www.lecompas.fr/wordpress/wp-content/uploads/2020/04/CompasZoom_signature-250x55.png"
          alt="logo Compas Zoom"
        />
      </Box>
    </Flex> */}

    <Flex alignItems="center">
      <Text fontSize="sm">
        © 2023 COMPAS-TIS
        <br />
        Le Comparateur des territoires.
        <br />
        Tous droits réservés
      </Text>
    </Flex>
    <Flex alignItems="center">
      <Icon name="info" />
      <Stack spacing={0} fontSize="sm" ml={2}>
        <Text>15 ter bd Jean Moulin</Text>
        <Text>44100 Nantes</Text>
      </Stack>
    </Flex>
    <Flex alignItems="center">
      <Icon name="phone" />
      <Text fontSize="sm" ml={2}>
        {" "}
        02.51.80.69.80
      </Text>
    </Flex>
    <Flex alignItems="center">
      <Icon name="email" />

      <Link href="mailto:contact@compas-tis.com" ml={2}>
        <Text fontSize="sm"> contact@compas-tis.com</Text>
      </Link>
    </Flex>
    <Flex alignItems="center">
      {/* <Box as={FaHome} /> */}
      <Box
        fontSize="sm"
        ml={2}
        as={Link}
        href="http://www.le-compas.fr"
        isExternal
      >
        <img src={imgLogo} width={"120px"} alt="logo compas"></img>
      </Box>
    </Flex>
  </Flex>
)
