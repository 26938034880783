import React from "react"
import {
  Box,
  Flex,
  Text,
  Menu,
  MenuButton,
  Button,
  MenuList,
  MenuItem,
  Link as ChakraLink,
  Icon,
} from "@chakra-ui/core"
import { Link } from "gatsby"

import { FaBars } from "react-icons/fa"

import imgLogo from "../images/logo.png"
import imgFavIcon from "../images/favicon.png"

export default ({ headerContainer, showMenu, title, img }) => {
  const H = ({ children }) => (
    <Flex
      ref={headerContainer}
      padding="0.5rem"
      justifyContent="space-between"
      alignItems="center"
      style={{
        position: "sticky",
        top: 0,
        backgroundColor: "#f7f7f3",
        zIndex: "100",
        color: "#33a9e1",
        borderBottom: "solid 1px #e1e1e1",
      }}
    >
      {children}
    </Flex>
  )

  if (!title) {
    return (
      <H>
        <Text fontSize="30px" fontWeight="600" ml={15} as={Link} to="/">
          Le comparateur des territoires
        </Text>
        <ChakraLink isExternal href="http://www.lecompas.fr">
          <img
            src={imgLogo}
            width="120px"
            style={{ display: "inline" }}
            alt="logo compas"
          />
          <Icon name="external-link" ml="5px" color="gray.300" />
        </ChakraLink>
      </H>
    )
  }

  return (
    <H>
      <Flex
        style={{ justifyContent: "center", alignItems: "center" }}
        as={Link}
        to="/"
      >
        <img
          src={imgFavIcon}
          style={{ width: "30px", height: "30px" }}
          alt="logo compas"
        />

        <Text fontSize="sm" ml={1}>
          Le comparateur des territoires
          <br />
          Compas
        </Text>
      </Flex>

      <Text as="h1" fontSize="24.5px" fontWeight={700}>
        {title}
        {img ? (
          <img
            src={img}
            style={{ display: "inline", width: "50px" }}
            alt="logo"
          ></img>
        ) : null}
      </Text>
      {showMenu ? (
        <Menu>
          <MenuButton
            as={Button}
            rightIcon="chevron-down"
            fontSize="xs"
            size="xs"
            transition="all 0.2s"
            rounded="md"
            borderWidth="1px"
            _hover={{ bg: "gray.100" }}
            _expanded={{ bg: "gray.200" }}
            _focus={{ outline: 0, boxShadow: "outline" }}
            backgroundColor="transparent"
          >
            <Box as={FaBars} />
          </MenuButton>
          <MenuList>
            <MenuItem as={Link} to="/" fontSize="xs">
              Accueil
            </MenuItem>
            <MenuItem as={Link} to="fragilite-economique" fontSize="xs">
              Baromètre - Fragilité économique
            </MenuItem>
            <MenuItem as={Link} to="sante" fontSize="xs">
              Baromètre - Fragilités en matière de santé
            </MenuItem>

            <MenuItem
              as={Link}
              to="fragilite-des-personnes-agees"
              fontSize="xs"
            >
              Baromètre - Fragilité des personnes âgées
            </MenuItem>

            <MenuItem as={Link} to="fragilite-des-enfants" fontSize="xs">
              Baromètre - Fragilité des enfants
            </MenuItem>

            <MenuItem as={Link} to="fragilite-acces-numerique" fontSize="xs">
              Baromètre - La fracture numérique
            </MenuItem>

            <MenuItem as={Link} to="dynamiques-des-territoires" fontSize="xs">
              Indice de développement des territoires
            </MenuItem>

            <MenuItem as={Link} to="niveaux-vie" fontSize="xs">
              Niveaux de vie
            </MenuItem>
            {/* <MenuItem as={Link} to="/femmes-hommes/" fontSize="xs">
              Egalité femmes-hommes
            </MenuItem> */}
          </MenuList>
        </Menu>
      ) : null}
    </H>
  )
}
